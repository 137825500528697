module.exports = [
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/people_strong.svg`,
    linkUrl: "https://ampersand.uat.peoplestrong.com/",
  },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/oracle.svg`,
    linkUrl: "https://login-ewzj-test-saasfaprod1.fa.ocs.oraclecloud.com/",
  },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/student_kare.svg`,
  //   linkUrl: "/",
  // },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/elevate.svg`,
  //   linkUrl: "/",
  // },
  {
    text: "LMS",
    // logo: `${process.env.PUBLIC_URL}/assets/images/logos/lms.svg`,
    linkUrl: "https://lms-frontend-ofsyw7livq-uc.a.run.app/",
  },
  {
    text: "Non Academic Survey",
    // logo: `${process.env.PUBLIC_URL}/assets/images/logos/non_academic_survey.svg`,
    linkUrl: "https://sara-uat.hubblehox.ai",
  },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/hubble_star.svg`,
    linkUrl: "https://hubblestar-uat.hubblehox.ai/#/vgos-internal",
  }
];